.navbar.sticky-top {
  z-index: 5000;
}

.pointer {
  cursor: pointer;
}

.ant-modal-footer {
  display: none;
}
#testid::placeholder {
  color: rgb(191, 191, 191);
  opacity: 1;
}

#testid2::placeholder {
  color: rgb(191, 191, 191);
  opacity: 1;
}

#testid3::placeholder {
  color: rgb(191, 191, 191);
  opacity: 1;
}

#testid4::placeholder {
  color: rgb(191, 191, 191);
  opacity: 1;
}

.ant-select-selector {
  height: 56px !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  margin-top: 5px;
}

.btn-square {
  border-radius: 0;
}

.css-yk16xz-control {
  border-color: #d9d9d9 !important;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #fff;
  border-radius: 0.75rem;
}

.icon-link {
  display: inline-flex;
  align-items: center;
}
.icon-link > .bi {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  transition: transform 0.25s ease-in-out;
  fill: currentColor;
}
.icon-link:hover > .bi {
  transform: translate(0.25rem);
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 0.75rem;
}

.rounded-4 {
  border-radius: 0.5rem;
}
.rounded-5 {
  border-radius: 1rem;
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
.text-shadow-2 {
  text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}
.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.product-search {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.172549) 0 6px 12px 0;
  cursor: pointer;
}

.search-element {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  border-right: 1px solid rgba(10, 10, 10, 0.1);
  padding: 1em;
}

.search-label {
  font-weight: 700;
}

.search-input {
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  font-size: 1.2em;
  line-height: 1.2em;
  margin-top: 5px;
}

.search-input:focus {
  box-shadow: 0px 2px 0px 0px tomato;
}

.search-button {
  display: inline-block;
  flex-grow: 0.5;
  height: 80px;
  line-height: 80px;
  padding: 1em !important;
  text-align: center;
  font-size: 1.8em;
  font-weight: 100;
  border: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  transition: background-color 0.8s ease;
}

.search-button:hover {
  background-color: HSLA(345, 100%, 60%, 1);
}

.btn-primary {
  background-color: #0071e3;
  border-color: #0071e3;
}

.dropdown {
  border: 1px solid #ddd;
  padding: 5px 5px 5px 12px;
}
.dropdown:hover {
  box-shadow: 0 4px 4px -2px rgba(33, 33, 33, 0.2);
}
